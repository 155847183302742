import React, {PureComponent} from 'react';

class App extends PureComponent {
  state = {
    moms: []
  }

  componentDidMount() {
    const apiURL = process.env.ADMIN_API_URL;
    fetch(`${apiURL}/moms`)
      .then(res => res.json())
      .then(data => data.Items)
      .then(this.updateMomsList);
  }

  updateMomsList = moms => {
    this.setState({moms});
  }

  render() {
    const {moms} = this.state;
    return (
      <ul>
        {moms.map(mom => <li>{mom.givenName} {mom.familyName}</li>)}
      </ul>
    );
  }
}

export default App;
